import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex"},[(!_vm.editing)?_c('div',[_vm._v(_vm._s(_vm.video.title))]):_c(VTextField,{attrs:{"label":"Video Title"},model:{value:(_vm.newTitle),callback:function ($$v) {_vm.newTitle=$$v},expression:"newTitle"}}),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"disabled":_vm.loading},on:{"click":function($event){_vm.editing = true}}},[_c(VListItemSubtitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"left":"","small":""}},[_vm._v("mdi-pencil")]),_c('span',[_vm._v(_vm._s(_vm.$t("videos.edit")))])],1)],1),_c(VListItem,{attrs:{"disabled":_vm.loading},on:{"click":_vm.remove}},[_c(VListItemSubtitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"left":"","small":""}},[_vm._v("mdi-delete")]),_c('span',[_vm._v(_vm._s(_vm.$t("videos.delete")))])],1)],1)],1)],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('VideoPlayer',{key:_vm.id,attrs:{"id":_vm.id,"url":_vm.url}}),_c('div',{staticClass:"my-4"}),(!_vm.editing)?_c('div',[_vm._v(_vm._s(_vm.video.description))]):_c(VTextarea,{attrs:{"label":_vm.$t('videos.desc'),"auto-grow":"","rows":"2"},model:{value:(_vm.newDescription),callback:function ($$v) {_vm.newDescription=$$v},expression:"newDescription"}})],1),(_vm.editing)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0"},on:{"click":function($event){_vm.editing = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VBtn,{attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.edit}},[_c('span',[_vm._v(_vm._s(_vm.$t("videos.edit")))])])],1):_vm._e()],1)],1),_c(VListItem,{on:{"click":function($event){_vm.dialog = true}}},[_c(VListItemContent,[_c('div',{staticClass:"font-weight-bold text-overline",staticStyle:{"line-height":"16px"}},[_c('span',{staticStyle:{"letter-spacing":"2px"}},[_vm._v("ID: #"+_vm._s(_vm.video.id))])]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.video.title))]),_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm._f("format")(_vm.video.createdAt)))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }